import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, tap } from 'rxjs/operators';
import { UtilityInformationService } from '@app/shared/services/utility-information.service';
import {
  LoadedConsumptionData,
  LoadedUtilityInformation,
  UtilityInformtationActionTypes as UtilityInformationActionTypes,
} from '../actions/utility-information.actions';
import { QuoteRequestService } from '@app/shared/services/quote-request.service';

@Injectable()
export class UtilityInformationEffects {
  loadUtilityInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UtilityInformationActionTypes.SetUtilityInformation),
      mergeMap((action) =>
        this.utilityInformationService
          .getUtilityInformation(action['state'], action['quoteType'], action['commodity'], action['zipCode'])
          .pipe(
            tap((utilityInformation) => console.log('Utility Information: ', utilityInformation)),
            map((utilityInformation) => new LoadedUtilityInformation(utilityInformation))
          )
      )
    )
  );

  loadConsumptionData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UtilityInformationActionTypes.LoadConsumptionData),
      mergeMap((action) =>
        this.quoteRequestService.getMontlyConsumptionData(action['quoteRequestId'], action['term'], action['uplift']).pipe(
          tap((consumptionData) => console.log('Consumption Data: ', consumptionData)),
          map((consumptionData) => new LoadedConsumptionData(consumptionData))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private utilityInformationService: UtilityInformationService,
    private quoteRequestService: QuoteRequestService
  ) {}
}
